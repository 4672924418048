import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const schedulerEvents = {
  state: {
    schedulerEvents: [],
    schedulerEvent: null
  },
  mutations: {
    setSchedulerEvents(state, schedulerEvents) {
      state.schedulerEvents = schedulerEvents;
    },
    setSchedulerEvent(state, schedulerEvent) {
      state.schedulerEvent = schedulerEvent;
    },
    callbackAddSchedulerEvent(state, newEvent) {
      state.schedulerEvents.push(newEvent);
    },
    callbackUpdateSchedulerEvent(state, updatedEvent) {
      const index = state.schedulerEvents.findIndex(event => event.id === updatedEvent.id);
      if (index !== -1) {
        state.schedulerEvents.splice(index, 1, updatedEvent);
      }
    },
    callbackRemoveSchedulerEvent(state, eventId) {
      for (var event in state.schedulerEvents) {
        if (state.schedulerEvents[event].id == eventId) {
          state.schedulerEvents.splice(event, 1)
        }
      }
    },
  },
  actions: {
    fetchSchedulerEvent(context, idEvent) {
      try {
        return ApiService.getRequest(`/scheduler_events/${idEvent}`).then(
          response => {
            this.commit('setSchedulerEvent', response.data);
            return response.data;
          });
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    fetchSchedulerEventsSpeaker({ context }, data) {
      return ApiService.getRequest('/scheduler_occurences_speaker/', {
        start: data.start,
        end: data.end,
        speaker_id: data.speaker_id
      }).then(
        response => {
          this.commit('setSchedulerEvents', response.data);
          return response.data;
        }
      ).catch( error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
      });
    },
    // POST :: Ajoute un nouvel evenement
    async addSchedulerEvents(context, newEvent) {
      try {
        const response = await ApiService.postRequest('/scheduler_events', newEvent);
        if (response.status === 201) {
            context.commit('callbackAddSchedulerEvent', response.data);
            Notify.create({
            message: 'L\'évènement a été ajoutée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // PUT :: Met à jour un evenement
    // TODO: Quand je fais l'update par le store j'ai l'erreur des 30secondes
    async updateSchedulerEvent(context, updatedEvent) {
      try {
        const response = await ApiService.patchRequest(`/scheduler_events/${updatedEvent.id}`, updatedEvent);
        if (response.status === 200) {
          context.commit('callbackUpdateSchedulerEvent', updatedEvent);
          Notify.create({
            message: 'L\'évènement a été mise à jour avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    // DELETE :: Supprime un evenement
    async removeSchedulerEvent(context, eventId) {
      try {
        const response = await ApiService.deleteRequest(`/scheduler_events/${eventId}`);
        if (response.status === 204) {
          context.commit('callbackRemoveSchedulerEvent', eventId);
          Notify.create({
            message: 'L\'évènement a été supprimée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getSchedulerEvents(state) {
      return state.schedulerEvents;
    },
    getSchedulerEvent(state) {
      return state.schedulerEvent;
    }
  }
};
