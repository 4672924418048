import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const etudiantPlanIndividuelFormation = {
  state: {
    etudiantPif: null,
    etudiantPifs: [],
    objet: null
  },
  mutations: {
    setetudiantPif(state, etudiantPif) {
      state.etudiantPif = etudiantPif;
    },
    setetudiantPifs(state, etudiantPifs) {
      state.etudiantPifs = etudiantPifs;
    },
    callbackAddetudiantPif(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditetudiantPif(state, payload) {
      state.objet = payload.objet;
    },
  },
  actions: {
    //  * le pif est récupéré dans l'objet étudiant

    /* CRUD PLAN INDIVIDUALISÉ DE FORMATION (POST/PATCH/DELETE) */
    async addEtudiantPif({ state }, data) {
      try {
        const response = await ApiService.postRequest('/etudiant_plan_individuel_formations', data);
        if (response.status === 201) {
          this.commit('callbackAddetudiantPif', response.data);
          Notify.create({
            message: 'Le pif a été ajouté avec succès.',
            color: 'green'
          })
          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editEtudiantPif(context, formData) {
      try {
        const response = await ApiService.patchRequest(`/etudiant_plan_individuel_formations/${formData.id}`, {
          etudiant: formData.etudiant,
          allegement: formData.allegement,
          objectifs: formData.objectifs,
          step_to_reach: formData.step_to_reach
        })
        if (response.status === 200) {
          this.commit('callbackEditetudiantPif', response.data)
          Notify.create({
            message: "Le pif a été modifié avec succès.",
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async statusEtudiantPif(context, formData) {
      try {
        const response = await ApiService.patchRequest(`${formData.uuid}`, { etat: formData.etat });
        if (response.status === 200) {
          this.commit('callbackEditetudiantPif', response.data);
          Notify.create({
            message: formData.message,
            color: 'green'
          });
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    }
  },
  getters: {
    getetudiantPif(state) {
      return state.etudiantPif;
    },
    getetudiantPifs(state) {
      return state.etudiantPifs;
    }
  }
}
