import AuthService from '@services/auth.service';

function getInitialState() {
  try {
    const user = JSON.parse(localStorage.getItem('user') || null);
    const roles = JSON.parse(localStorage.getItem('roles') || null);
    const token = JSON.parse(localStorage.getItem('token') || null);
    return {
      status: { loggedIn: !!user },
      user,
      roles,
      token,
    };
  } catch (e) {
    console.error("Failed to parse auth data from localStorage", e);
    return {
      status: { loggedIn: false },
      user: null,
      roles: null,
      token: null,
    };
  }
}


export const auth = {
  namespaced: true,
  state: getInitialState(),
  actions: {

    login({ commit }, credentials) {
      return AuthService.login(credentials).then(
        user => {
          localStorage.setItem('user', JSON.stringify(user)); // Exemple de stockage
          commit('loginSuccess', user);
          return user;
        },
        error => {
          commit('loginFailure');
          throw error; // Propager l'erreur pour un traitement ultérieur
        }
      );
    },
    logout({ commit }) {
      console.log('logout dispatched');
      localStorage.clear(); // Suppression unique suffisante
      // commit('logout');
    },
    refreshState({ commit, dispatch }) {
      AuthService.refreshUserData().then(
        data => {
          localStorage.setItem('user', JSON.stringify(data)); // Assurer la cohérence des données
          commit('updateUserData', data);
        },
        error => {
          dispatch('logout');
          throw error;
        }
      );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.roles = null;
      state.token = null;
      localStorage.clear();
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.roles = null;
      state.token = null;
      localStorage.clear();
    },
    updateUserData(state) {
      const user = JSON.parse(localStorage.getItem('user'))
      const roles = JSON.parse(localStorage.getItem('roles'))
      const token = JSON.parse(localStorage.getItem('token'));

      if (user && roles && token) {
        state.user = user;
        state.roles = roles;
        state.token = token;
      }
    }
  },
  getters: {
    isLoggedIn: state => state.status.loggedIn
  }
};
