import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const antennePedagogieDocuments = {
  state: {
    documents: [],
    document: null,
    objet: null
  },
  mutations: {
    setDocument(state, document) {
      state.document = document;
    },
    callbackAddDocument(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditDocument(state, payload) {
      state.objet = payload.objet;
    },
    callbackdeletePedagogieDocument(state, payload) {
      state.objet = payload.objet;
    }
  },
  actions: {
    // transféré dans le fichier documents
    // async downloadDocument(context, uri) {
    //   try {
    //     await ApiService.downloadRequest({
    //       url: uri.substring(1) + '/download',
    //     })
    //   } catch (error) {
    //     console.error(error);
    //     Notify.create({
    //       message: "Une erreur s'est produite",
    //       color: "red"
    //     })
    //     return Promise.reject(error);
    //   }
    // },
    //  * transforme un objet en formulaire de données
    async transformDataToForm({ state, dispatch }, data) {
      try {
        let form = new FormData();
        form.append('evaluation', data.evaluation);
        form.append('antenne', data.antenne)
        form.append('name', data.name);
        form.append('file', data.file);
        form.append('type', data.type);

        await dispatch("addPedagogieDocument", form, { root: true })
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // * Ajout d'un document en bdd
    async addPedagogieDocument({ state }, data) {
      try {
        const response = await ApiService.postRequest('/antenne_pedagogie_documents', data);
        if (response.status === 201) {
          this.commit('callbackAddDocument', response.data)
          Notify.create({
            message: 'Le document a été ajouté avec succès.',
            color: 'green'
          })

          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editPedagogieDocument(context, data) {
      try {
        const response = await ApiService.postRequest(`/antenne_pedagogie_document/${data.uuid}`, data)
        if (response.status === 200) {
          this.commit('callbackEditDocument', response.data)
          Notify.create({
            message: 'Le document a été modifié avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * supprime un document
    async deletePedagogieDocument(context, uri){
      try {
        const response = await ApiService.deleteRequest(uri)
        if (response.status === 204) {
          this.commit('callbackdeletePedagogieDocument', response.data)
          Notify.create({
            message: "Le document a bien été supprimé !",
            color: "green"
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    }
  },
  getters: {
    getDocumentPedagogie(state) {
      return state.document;
    },
    getDocumentsPedagogie(state) {
      return state.documents;
    }
  }
}
