import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const entrepriseTaxes = {
  state: {
    entrepriseTaxes: []
  },
  mutations: {
    setEntrepriseTaxes(state, entrepriseTaxes) {
      state.entrepriseTaxes = entrepriseTaxes;
    },
    callbackAddEntrepriseTaxe(state, newEntrepriseTaxe) {
      state.entrepriseTaxes.push(newEntrepriseTaxe);
    },
    callbackUpdateEntrepriseTaxe(state, updatedEntrepriseTaxe) {
      const index = state.entrepriseTaxes.findIndex(entrepriseTaxe => entrepriseTaxe.id === updatedEntrepriseTaxe.id);
      if (index !== -1) {
        state.entrepriseTaxes.splice(index, 1, updatedEntrepriseTaxe);
      }
    },
    callbackRemoveEntrepriseTaxe(state, entrepriseTaxeId) {
      for (var entrepriseTaxe in state.entrepriseTaxes) {
        if (state.entrepriseTaxes[entrepriseTaxe].id == entrepriseTaxeId) {
          state.entrepriseTaxes.splice(entrepriseTaxe, 1)
        }
      }
    },
  },
  actions: {
    // GET :: Récupère la liste des taxes de l'entreprise
    fetchEntrepriseTaxes(context, id_entreprise) {
      return ApiService.getRequest(`/entreprises/${id_entreprise}/taxes`).then(
        response => {
          context.commit('setEntrepriseTaxes', response.data['hydra:member']);
        }
      )
        .catch( error => { console.error(error) } );
    },
    // POST :: Ajoute une nouvelle taxe
    async addEntrepriseTaxe(context, newEntrepriseTaxe) {
      try {
        const response = await ApiService.postRequest('/entreprise_taxes', newEntrepriseTaxe);
        if (response.status === 201) {
          context.commit('callbackAddEntrepriseTaxe', response.data);
          Notify.create({
            message: 'La taxe a été ajoutée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          });
          return Promise.reject();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject();
      }
    },
    // PATCH :: Met à jour une taxe
    async updateEntrepriseTaxe(context, updatedEntrepriseTaxe) {
      try {
        const response = await ApiService.patchRequest(`/entreprise_taxes/${updatedEntrepriseTaxe.id}`, updatedEntrepriseTaxe);
        if (response.status === 200) {
          context.commit('callbackUpdateEntrepriseTaxe', response.data);
          Notify.create({
            message: 'La taxe a été mise à jour avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          });
          return Promise.reject();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject();
      }
    },
    // DELETE :: Supprime une taxe
    async removeEntrepriseTaxe(context, entrepriseTaxeId) {
      try {
        const response = await ApiService.deleteRequest(`/entreprise_taxes/${entrepriseTaxeId}`);
        if (response.status === 204) {
          context.commit('callbackRemoveEntrepriseTaxe', entrepriseTaxeId);
          Notify.create({
            message: 'La taxe a été supprimée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          });
          return Promise.reject();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject();
      }
    },
  },
  getters: {
    getEntrepriseTaxes(state) {
      return state.entrepriseTaxes;
    }
  }
};
