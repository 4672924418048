import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const documents = {
  state: {
    documents: [],
    document: null,
    objet: null
  },
  mutations: {
    callbackAddDocument(state, newDocument) {
      state.documents.push(newDocument);
    },
    callbackDeleteDocument(state, payload) {
      state.objet = payload.objet;
    },
    setDocuments(state, documents) {
      state.documents = documents;
    }
  },
  actions: {
    async fetchEntityDocumentsSubResource(context, uri) {
      try {
        return ApiService.getRequest(`${uri}`).then(response => {
          context.commit('setDocuments', response.data['hydra:member']);
        }).catch( error => { console.error(error) } );
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async addDocument(context, route, newDocument) {
      console.log('NEW DOCUMENT :: ', newDocument)
      try {
        const response = await ApiService.postRequest(route, newDocument);
        if(response.status === 201) {
          context.commit('callbackAddDocument', response.data);
          Notify.create({
            message: 'Document ajouté avec succès',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // DOWNLOAD :: Télécharge le document
    async downloadDocument(context, uri) {
      try {
        await ApiService.downloadRequest({url: uri.substring(1) + '/download'})
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // DELETE :: Supprime le document
    async deleteDocument(context, uri) {
      try {
        const response = await ApiService.deleteRequest(uri);
        if (response.status === 204) {
          context.commit('callbackDeleteDocument', response.data);
          Notify.create({
            message: "Le document a été supprimé avec succès",
            color: "green"
          })
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getDocument(state) {
      return state.document;
    },
    getDocuments(state) {
      return state.documents;
    }
  }
}
