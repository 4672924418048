import ApiService from "../../services/api.service";
// import Notify from 'quasar/src/plugins/Notify'
 import { Notify } from 'quasar'; 

export const schedulerActivityModules = {
  // *** C'est l'endroit où vous stockez les données.
  // ** On accède à l'état en utilisant ``this.$store.state`` dans les composants Vue, ou via ``mapState`` dans l'option ``computed``.
  state: {
    schedulerActivityModules: []
  },
  // *** Fonctions synchrones qui modifient l'état du store. Elles sont le seul moyen de modifier l'état.
  // ** On invoque une mutation en appelant ``commit`` dans les composants Vue.
  mutations: {
    setSchedulerActivityModules(state, schedulerActivityModules) {
      state.schedulerActivityModules = schedulerActivityModules;
    },
    callbackAddSchedulerActivityModule(state, newActivityModule) {
      state.schedulerActivityModules.push(newActivityModule);
    },
    callbackUpdateSchedulerActivityModule(state, updatedActivityModule) {
      const index = state.schedulerActivityModules.findIndex(activityModule => activityModule.id === updatedActivityModule.id);
      if (index !== -1) {
        state.schedulerActivityModules.splice(index, 1, updatedActivityModule);
      }
    },
    callbackRemoveSchedulerActivityModule(state, activityModuleId) {
      for (var activityModule in state.schedulerActivityModules) {
        if (state.schedulerActivityModules[activityModule].id == activityModuleId) {
          state.schedulerActivityModules.splice(activityModule, 1)
        }
      }
    },
  },
  // *** Fonctions qui peuvent être asynchrones et effectuer des opérations asynchrones, telles que les appels API, puis invoquer des mutations pour modifier l'état en conséquence.
  // ** On invoque une action en appelant ``dispatch`` dans les composants Vue.
  actions: {
    // GET :: Récupère la listes des modules d'activité
    fetchAllSchedulerActivityModules(context) {
      return ApiService.getRequest('/scheduler_activity_modules').then(
        response => {
        context.commit('setSchedulerActivityModules', response.data['hydra:member']);
      }).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
      });
    },
    // POST :: Ajoute un nouveau module d'activité
    async addSchedulerActivityModule(context, newActivityModule) {
      try {
        const response = await ApiService.postRequest('/scheduler_activity_modules', newActivityModule);
        if (response.status === 201) {
           context.commit('callbackAddSchedulerActivityModule', response.data);
           Notify.create({
            message: 'Le module a été ajouté avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // PUT :: Met à jour un module d'activité
    async updateSchedulerActivityModule(context, updatedActivityModule) {
      try {
        const response = await ApiService.patchRequest(`/scheduler_activity_modules/${updatedActivityModule.id}`, updatedActivityModule);
        if (response.status === 200) {
          context.commit('callbackUpdateSchedulerActivityModule', updatedActivityModule);
          Notify.create({
            message: 'Le module a été mise à jour avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    // DELETE :: Supprime un module d'activité
    async removeSchedulerActivityModule(context, activityModuleId) {
      try {
        const response = await ApiService.deleteRequest(`/scheduler_activity_modules/${activityModuleId}`);
        if (response.status === 204) {
          context.commit('callbackRemoveSchedulerActivityModule', activityModuleId);
          Notify.create({
            message: 'Le module a été supprimée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getSchedulerActivityModules(state) {
      return state.schedulerActivityModules;
    },
  },
};