import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const schedulerSpeakers = {
  state: {
    schedulerSpeakers: [],
    schedulerSpeaker: null,
  },
  mutations: {
    setSchedulerSpeakers(state, schedulerSpeakers) {
      state.schedulerSpeakers = schedulerSpeakers;
    },
    setSchedulerSpeaker(state, schedulerSpeaker) {
      state.schedulerSpeaker = schedulerSpeaker;
    },
    callbackAddSchedulerSpeaker(state, newSpeaker) {
      state.schedulerSpeakers.push(newSpeaker);
    },
    callbackUpdateSchedulerSpeaker(state, updatedSpeaker) {
      const index = state.schedulerSpeakers.findIndex(speaker => speaker.id === updatedSpeaker.id);
      if (index !== -1) {
        state.schedulerSpeakers.splice(index, 1, updatedSpeaker);
      }
    },
    callbackRemoveSchedulerSpeaker(state, speakerId) {
      for (var speaker in state.schedulerSpeakers) {
        if (state.schedulerSpeakers[speaker].id == speakerId) {
          state.schedulerSpeakers.splice(speaker, 1)
        }
      }
    },
    // Push l'activité dans l'intervenants
    callbackAddSchedulerActivityToSpeakers(state, { activity, speakers }) {
      speakers.forEach(speakerId => {
        const speakerIndex = state.schedulerSpeakers.findIndex(speaker => speaker.id === speakerId);
        if (speakerIndex !== -1) {
          state.schedulerSpeakers[speakerIndex].activities.push(activity);
        }
      });
    },
    // Edit activité type d'intervenant
    callbackUpdateSchedulerActivityInSpeakers(state, activity) {
      // Retire l'activité des speakers qui ne sont pas dans le tableau speakers
      state.schedulerSpeakers.forEach(speaker => {
        if (!activity.speakers.includes(speaker['@id'])) {
          const activityIndex = speaker.activities.findIndex(a => a['@id'] === activity['@id']);
          if (activityIndex !== -1) {
            speaker.activities.splice(activityIndex, 1);
          }
        }
      });

      // Ajoute l'activité à tous les speakers
      activity.speakers.forEach(speakerId => {
        const speakerIndex = state.schedulerSpeakers.findIndex(speaker => speaker['@id'] === speakerId);
        if (speakerIndex !== -1) {
          if (!state.schedulerSpeakers[speakerIndex].activities.some(a => a.id === activity.id)) {
            state.schedulerSpeakers[speakerIndex].activities.push(activity);
          }
        }
      });
    }
  },
  actions: {
    // *** CRUD ***
    // GET :: Récupère la listes des intervenants
    // version light pour les filtres
    fetchSchedulerSpeakers(context) {
      return ApiService.getRequest('/speakers_light_list').then(
        response => {
          context.commit('setSchedulerSpeakers', response.data['hydra:member']);
        }
      ).catch( error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
      });
    },
    // version complete
    fetchAllSchedulerSpeakers(context) {
      return ApiService.getRequest('/scheduler_speakers').then(
        response => {
          context.commit('setSchedulerSpeakers', response.data['hydra:member']);
        }).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
      });
    },
    fetchSchedulerSpeaker(context, uriSpeaker) {
      return ApiService.getRequest(`${uriSpeaker}`).then(
        response => {
          context.commit('setSchedulerSpeaker', response.data['hydra:member']);
        }
      ).catch( error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
      });
    },
    // POST :: Ajoute un nouvel intervenant
    async addSchedulerSpeaker(context, newSpeaker) {
      try {
        const response = await ApiService.postRequest('/scheduler_speakers', newSpeaker);
        if (response.status === 201) {
          context.commit('callbackAddSchedulerSpeaker', response.data);
          Notify.create({
            message: 'L\'intervenant a été ajoutée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // PUT :: Met à jour un intervenant
    async updateSchedulerSpeaker(context, updatedSpeaker) {
      try {
        const response = await ApiService.patchRequest(`/scheduler_speakers/${updatedSpeaker.id}`, updatedSpeaker);
        if (response.status === 200) {
          context.commit('callbackUpdateSchedulerSpeaker', updatedSpeaker);
          Notify.create({
            message: 'L\'intervenant a été mise à jour avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    // DELETE :: Supprime un intervenant
    async removeSchedulerSpeaker(context, speakerId) {
      try {
        const response = await ApiService.deleteRequest(`/scheduler_speakers/${speakerId}`);
        if (response.status === 204) {
          context.commit('callbackRemoveSchedulerSpeaker', speakerId);
          Notify.create({
            message: 'L\'intervenant a été supprimée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // *** AUTRES ***
    // Ajoute une activité au speaker
    addSchedulerActivityToSpeakers(context, { activity, speakers }) {
      context.commit('callbackAddSchedulerActivityToSpeakers', { activity, speakers });
    },
    updateSchedulerActivityInSpeakers(context, activity) {
      context.commit('callbackUpdateSchedulerActivityInSpeakers', activity);
    },
  },
  getters: {
    getSchedulerSpeakers(state) {
      return state.schedulerSpeakers;
    },
    getSchedulerSpeaker(state) {
      return state.schedulerSpeaker;
    },
  }
};
