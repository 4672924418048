import ApiService from "@services/api.service";
import { Notify } from 'quasar';

/*Pour les modeles dans l'antenne*/
export const modeles = {
  state: {
    modeles: [],
    modele: null,
    /*Dossiers des Modeles*/
    dossiers: [],
    dossier: null,

    variables: [],
    variable: null,
  },
  mutations: {
    setModeles(state, modeles) {
       state.modeles = modeles;
    },
    setModele(state, modele) {
      state.modele = modele;
    },
    setVariables(state, variables) {
      state.variables = variables;
    },
    setVariable(state, variable) {
      state.variable = variable;
    },
    setDossiers(state, dossiers)
    {
      state.dossiers = dossiers;
    },
    setDossier(state, dossier)
    {
      state.dossier = dossier;
    },
    callbackEditModele(state, payload)
    {
      state.objet = payload.objet;
    },
  },
  actions: {
    async fetchModeleDossier(context)
    {
      return await ApiService.getRequest('modele_dossiers').then(
        response => {
          let dossiers = response.data['hydra:member'];
          context.commit('setDossiers', dossiers);
        }
      )
    },
    async fetchModeles(context)
      {
        return await ApiService.getRequest('modeles').then(
          response => {
            let modeles = response.data['hydra:member'];
            context.commit('setModeles', modeles);
          }
        )
        .catch (error => {
          console.error(error);
          Notify.create({
            message: "Une erreur est survenue lors de la récupération des modèles",
            color: 'red'
          })
        })
      },
    async addModele(context, formData)
    {
      try {
        const response = await ApiService.postRequest('modeles', formData);
        context.commit('setModele', response.data);
        Notify.create({
          message: "Modèle ajouter avec succès.",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite à l'ajout du modèle.",
          color: 'red'
        })
      }
    },
    addLiaisonModele(context, form_data)
    {
      try {
        ApiService.postRequest('/modele_action_formations', form_data);
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de l'ajout du modèle",
          color: 'red'
        })
      }
    },
    async editModele(context, data)
    {
      try {
        let response = await ApiService.postRequest(`modeles/${data.url}`, data.formData);
        if(response.status === 201)
        {
          context.commit('callbackEditModele', response.data);
          Notify.create({
            message: "Le modèle a été modifié avec succès",
            color: 'green'
          })
        }
      }catch (error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite à la modification du modèle",
          color:'red'
        })
      }
    },
     editLiaisonModele(context, data)
    {
      try {
        let response = ApiService.postRequest(`${data.url}`, data.formData);
        if(response.status === 201)
        {
          context.commit('callbackEditModele', response.data)
        }
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la suppression.",
          color: 'red'
        })
      }
    },
  },
  getters: {
    getModeles(state) {
      return state.modeles;
    },
    getModele(state) {
      return state.modele;
    },
    getVariables(state) {
      return state.variables
    },
    getVariable(state) {
      return state.variable
    },
    getdossiers(state){
      return state.dossiers;
    },
    getdossier(state){
      return state.dossier;
    }
  }
}
