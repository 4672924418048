import axios from 'axios'
import authHeader from './auth-header'
import store from '@store/index'
import Qs from 'qs'

const API_URL = process.env.API;

const getHeaders = (headers) => {
  if (!headers || headers === {}) {
    headers = {
      'Content-Type': 'application/json'
    }
  }
  return Object.assign(authHeader(), headers);
}

class ApiService {

  async fetchObjectFromIRI(objectIRI, fetchBaseUrl) {
    if(typeof objectIRI !== "string" && objectIRI !== null && typeof objectIRI !== "undefined") {
      throw `Parameter objectIRI with value ${objectIRI} can only be a string or undefined`
    }

    let split_string = objectIRI && typeof objectIRI === "string" && objectIRI.split("/")
    let id_object = split_string && split_string[split_string.length - 1]

    if(!id_object) {
      return Promise.resolve(null)
    }

    if(isNaN(parseInt(id_object))) {
      throw `Cannot fetch object with IRI ${objectIRI} because its parsed ID is NaN`
    }

    return this.getRequest(fetchBaseUrl + id_object).then(
      response => response.data
    )
  }

  getRequest(url, params, withoutLoading) {
    if(typeof withoutLoading === 'undefined' || withoutLoading !== true) {
      store().state.globalstore.loading = true
    }
    url = url.replace(/^\/|\/$/g, '');

    let instance = axios.create()
    instance.interceptors.request.use(config => {
      config.paramsSerializer = params => {
        return Qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false,
          skipNulls: true
        });
      };
      return config;
    });

    return instance.get(API_URL + url, { params: (params || {}), headers: getHeaders() })
          .then(
			  		response => response
			  	)
			  	.catch(function (error) { return Promise.reject(error.response) })
			  	.finally(() => store().state.globalstore.loading = false)
  }

  postRequest(url, params, withoutLoading) {
    if(typeof withoutLoading === 'undefined' || withoutLoading !== true) {
      store().state.globalstore.loading = true
    }
  	return axios.post(API_URL + url, params, { headers: authHeader() })
  				.then(
			  		response => response
			  	)
			  	.catch(function (error) { return Promise.reject(error.response) })
			  	.finally(() => store().state.globalstore.loading = false)
  }

  putRequest(url, params, headers, withoutLoading) {
    if(typeof withoutLoading === 'undefined' || withoutLoading !== true) {
      store().state.globalstore.loading = true
    }
    if (params instanceof FormData) {
      params = JSON.stringify(Object.fromEntries(params))
      console.warn('Please use json object instead formData, some break types are possible')
    }
    return axios.put(API_URL + url, params, { headers: getHeaders(headers) })
          .then(
            response => response
          )
          .catch(function (error) { return Promise.reject(error.response) } )
          .finally(() => store().state.globalstore.loading = false)
  }
  patchRequest(url, params, headers, withoutLoading) {
    if(typeof withoutLoading === 'undefined' || withoutLoading !== true) {
      store().state.globalstore.loading = true
    }
    if (params instanceof FormData) {
      params = JSON.stringify(Object.fromEntries(params))
      console.warn('Please use json object instead formData, some break types are possible')
    }
    return axios.patch(API_URL + url, params, { headers: getHeaders(headers) })
      .then(
        response => response
      )
      .catch(function (error) { return Promise.reject(error.response) })
      .finally(() => store().state.globalstore.loading = false)
  }

  // async patchRequest(url, params, headers) {
  //   store().state.globalstore.loading = true
  //   console.log(params, params instanceof FormData)
  //   if (params instanceof FormData) {
  //     params = await new Promise( function (resolve, reject) {
  //       resolve(JSON.stringify(Object.fromEntries(params)))
  //     })
  //   }
  //   console.log(getHeaders({ headers: { 'Content-Type': 'application/merge-patch+json' } }))
  //   return axios.patch(API_URL + url, params, { headers: getHeaders({ headers: { 'Content-Type': 'application/merge-patch+json' } }) })
  //       .then(
  //         response => response
  //       )
  //       .catch(function (error) { return Promise.reject(error.response) })
  //       .finally(() => store().state.globalstore.loading = false)
  // }

  deleteRequest(url) {
    store().state.globalstore.loading = true
    return axios.delete(API_URL + url, { headers: authHeader() })
          .then(
            response => response
          )
          .catch(function (error) { return Promise.reject(error.response) })
          .finally(() => store().state.globalstore.loading = false)
  }
  downloadRequest(config) {
    store().state.globalstore.loading = true
    config.responseType = 'blob'
    config.headers = authHeader()
    return axios(config)
      .then(
        response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = response.headers['content-disposition'] ? response.headers['content-disposition'].split('filename=')[1].split(';')[0] : config.url.split('?')[0]
          document.body.appendChild(link);
          link.click()
          URL.revokeObjectURL(link.href)
        }
      )
      .catch(function (error) { return Promise.reject(error.response) })
      .finally(() => store().state.globalstore.loading = false)
  }
  async displayProtectedImage(imageUrl) {
    const response = await axios({ method: 'get', url: imageUrl, responseType: 'blob', headers: authHeader() })
    return URL.createObjectURL(response.data);
    // const base64 = arrayBufferToBase64(response.data);
    // console.log(`data:image/png;base64,${base64}`, response.data)
    // return `data:image/png;base64,${base64}`;
    // const mimeType = response.headers['content-type'].toLowerCase()
    // const imgBase64 = new global.Buffer(response.data, 'binary').toString('base64')
    // return 'data:' + mimeType + ';base64,' + imgBase64
    // return image
    // const reader = new FileReader()
    // // console.log(image)
    // return new Promise((resolve, reject) => {
    //   reader.onloadend = () => resolve(reader.result.toString('base64'))
    //   reader.readAsDataURL(response.data)
    // });
  }
}

export default new ApiService();
