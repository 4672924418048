import ApiService from "../../services/api.service";
import { Notify } from 'quasar';
import { useStore } from 'vuex';

export const userAlertes = {
  state: {
    userAlertes: [],
    userAlerte: null,
    object: null
  },
  mutations: {
    setUserAlertes(state, userAlertes) {
      state.userAlertes = userAlertes;
    },
    setUserAlerte(state, userAlerte) {
      state.userAlerte = userAlerte;
    },
    callbackEditAlertes(state, payload) {
      state.object = payload.object;
    }
  },
  actions: {
    /*ALERTE TYPE UTILISATEUR (POST, DELETE, GET) */
    async addUserParametersAlertes({state}, data){
      try {
       const response = await ApiService.postRequest('edit_user_alertes_global_params/', data);
        if(response.status === 200) {
          this.commit('callbackEditAlertes', response.data);
          Notify.create({
            message: 'Les paramètres de vos alertes ont bien été ajoutés avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de l'ajout de vos données",
          color: "red"
        })
        return Promise.reject(error);
      }
    },

    async statusAlertes(context, alerteEtat){
      try {
        const response = await ApiService.patchRequest(`${alerteEtat.uri}`, {etat:alerteEtat.etat});
        if(response.status === 200){
          this.commit(' callbackEditAlertes', response.alerteEtat)
          Notify.create({
            message: 'Le status de cette alerte a été enregister avec succès',
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: 'red'
        })
        return Promise.reject(error)
      }
    },
    // async deleteUserAlertes({state}, data) {
    //   try {
    //     const response = await ApiService.deleteRequest(`${data}`)
    //     if(response.status === 204){
    //       this.commit('callbackEditAlertes', response.data)
    //       Notify.create({
    //         message: "Vos paramètres on été modifiés avec succès.",
    //         color: 'green'
    //       })
    //       return Promise.resolve();
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     Notify.create({
    //       message: "Une erreur s'est produite vos paramètres n'ont pas été modifiés",
    //       color: 'red'
    //     })
    //     return Promise.reject(error)
    //   }
    //
    // },

    fetchUserAlertes({commit, rootState, rootGetters}){
      // On quitte la fonction car l'utilisateur n'est pas connecté
      if (!rootGetters['auth/isLoggedIn']) {
        return;
      }
      const userId = rootState.auth.user?.id;
      return ApiService.getRequest('user_alertes_global_params', {user: userId}).then(
          response =>{
            commit('setUserAlertes', response.data['hydra:member']);
          }
      ).catch(error => {
        console.error(error);
        Notify.create({
          message: "Un erreur s'est produite",
          color: 'red'
        })
      })
    }
  },
  getters: {
    getUserAlertes(state) {
      return state.userAlertes;
    },
    getUserALerte(state){
      return state.userAlerte;
    }
  }
}
