<template>
  <div id="q-app">
    <router-view />

    <div id="fullpage-loader"
    	v-if="loading"
    >
	    <q-spinner-gears
	      color="primary"
	      size="4em"
	    />
	</div>
  </div>
</template>

<script>

export default {
  name: 'App',
  computed:{
  	loading: function() {
  		return this.$store.state.globalstore.loading
  	}
  },
  beforeMount: function() {
	  // Check if authenticated
  }
}
</script>

<style>
  /* @font-face{
    font-family:"Wigrum";
    src:url(assets/fonts/Wigrum-Regular.otf)
  } */

  * {
    font-family: 'Helvetica Neue', sans-serif;
  }

	#fullpage-loader{
		position: fixed;
		top: 50px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		background: rgba(255,255,255,0.5);
		z-index: 9990;
		display: flex;
		align-items: center;
		justify-content: center;

	}
  .vue-notification-group {
    z-index: 9999;
  }

  .q-field__native[required] ~ .q-field__label:after {
    content: '*';
    color: red;
  }

</style>
