import ApiService from '@services/api.service';
import { Notify } from 'quasar';

export const prestataires = {
  state: {
    prestataires: [],
    prestataire: null,
    objet: null,
    competences: [],
    competence: null,
    collection: null
  },
  mutations: {
    setPrestataires(state, prestataires) {
      state.prestataires = prestataires;
    },
    setPrestataire(state, prestataire) {
      state.prestataire = prestataire;
    },
    setCollection(state, collection) {
      state.collection = collection;
    },
    setCompetences(state, competences) {
      state.competences = competences;
    },
    setCompetence(state, competence) {
      state.competence = competence;
    },
    callbackSelectProvider(state, prestataire) {
      state.prestataire = prestataire;
    },
    callbackAddPrestataire(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditPrestataire(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditStatusPrestataire(state, payload) {
      state.objet = payload.objet;
    },
  },
  actions: {
    /* PRESTATAIRES (GET) */
    // Antenne récupère ses prestataires => cfa récupère l'ensemble des prestataires
    fetchPrestataires(context) {
      return ApiService.getRequest('/providers_list').then(
        response => {
          context.commit('setPrestataires', response.data['hydra:member']);
        }
      ).catch( error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
      });
    },
    fetchPrestataire(context, prestataire) {
      return ApiService.getRequest(`${prestataire}`).then(
        response => {
          context.commit('setPrestataire', response.data);
        }
      ).catch( error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
      });
    },
    fetchPrestataireSubResource(context, uri) {
      return ApiService.getRequest(`${uri}`).then(
        response => {
          context.commit('setCollection', response.data['hydra:member']);
        }
      ).catch( error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
      });
    },
    //  * liste des compétences types
    fetchPrestatairesCompetencesTypes(context) {
      return ApiService.getRequest('/prestataire_competence_types').then(
        response => {
          context.commit('setCompetences', response.data['hydra:member']);
        }
      ).catch( error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
      });
    },

    /* CRUD PRESTATAIRES (POST / PATCH) */
    async saveFicheSignalitiquePrestataire({ state, commit }, data) {
      try {
        const response = await ApiService.postRequest('/prestataires', data)
        this.commit('setPrestataire', response.data);
        Notify.create({
          message: 'Données de la fiche signalitique sauvegardées.',
          color: 'green'
        });
        return response.data;
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    },
    async editPrestataire({ state }, data) {
      try {
        const response = await ApiService.patchRequest(`${data.uri}`, data.form);
        if (response.status === 200) {
          this.commit('callbackEditPrestataire', response.data);
          Notify.create({
            message: 'Le prestataire a été modifié avec succès.',
            color: 'green'
          });
          return response.data;
        } else {
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          });
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    },
    async editStatusPrestataire({ state }, data) {
      try {
        const response = await ApiService.patchRequest(`${data.uri}`, { etat: data.etat });
        if (response.status === 200) {
          this.commit('callbackEditPrestataire', response.data);
          Notify.create({
            message: data.message,
            color: 'green'
          });
          return Promise.resolve();
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    },
    async providerPermanentDeletion({ state }, uri) {
      try {
        await ApiService.deleteRequest(`${uri}`);
        Notify.create({
          message: "Le prestataire a été supprimé définitivement avec succès.",
          color: "green"
        })
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    },

    /* CRUD PRESTATAIRES COLLECTIONS (POST / PATCH) */
    //  ajout des collections du prestataire - méthode unique
    //  modifier le controleur si ajout de collection
    async addPrestataireCollection({ state, commit }, data){
      try {
        const response = await ApiService.postRequest('/prestataire_add_collection', data);
        this.commit('setCollection', response.data);
        Notify.create({
          message: data.message,
          color: 'green'
        });
        return response.data;
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    },
    //  * édition des collections du prestataire - méthode unique
    async editPrestataireCollection({ state }, data) {
      try {
        const response = await ApiService.patchRequest(`${data.uri}`, data.form);
        if (response.status === 200) {
          this.commit('setCollection', response.data);
          Notify.create({
            message: data.message,
            color: 'green'
          });
          return response.data;
        } else {
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          });
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    },
    //  * édition des status de rattachement avec conventions - controller
    async editPrestataireRattachement({ state }, data) {
      try {
        const response = await ApiService.postRequest(`prestataire_rattachement_conventions`, data.form);

        this.commit('setCollection', response.data);
        Notify.create({
          message: data.message,
          color: 'green'
        });
        return response.data;
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    },
    async editPhotoPrestataire({ state }, formData) {
      let uriProvider = '';
      for (var pair of formData.entries()) {
        if (pair[0] === 'uri') {
          uriProvider = pair[1];
          delete pair[0];
        }
        // console.log(pair[0]+ ', ' + pair[1]);
      }
      try {
        const response = await ApiService.postRequest(`${uriProvider}`, formData);
        if (response.status === 201) {
          this.commit('setPrestataire', response.data);
          Notify.create({
            message: 'Photo modifiée avec succès.',
            color: 'green'
          });
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },

    /* CRUD PRESTATAIRES COMPETENCES TYPES COLLECTION (POST / PATCH / DELETE) */
    async addPrestataireCompetenceType({ state }, data) {
      try {
        const response = await ApiService.postRequest('/prestataire_competence_types', data);

        if (response.status === 201) {
          this.commit('setCompetence', response.data);
          Notify.create({
            message: 'La compétence type a été ajoutée avec succès.',
            color: 'green'
          });
          return response.data;
        }
      }  catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editPrestataireCompetenceType({ state }, data) {
      try {
        const response = await ApiService.patchRequest(`${data.uri}`, data.form);

        if (response.status === 200) {
          this.commit('setCompetence', response.data);
          Notify.create({
            message: data.message,
            color: 'green'
          });
          return response.data;
        }
      }  catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async permanentRemovePrestataireCompetenceType({ state }, data) {
      try {
        await ApiService.deleteRequest(`${data}`);
        Notify.create({
          message: 'Compétence type supprimée avec succès. (suppression définitive)',
          color: 'green'
        });
        return Promise.resolve();
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    }
  },
  getters: {
    getPrestataires(state) {
      return state.prestataires;
    },
    getPrestataire(state) {
      return state.prestataire;
    },
    getPrestatairesCompetencesTypes(state) {
      return state.competences;
    },
    getPrestatairesCompetenceType(state) {
      return state.competence;
    },
    getPrestataireSubResource(state) {
      return state.collection;
    }
  }
}
