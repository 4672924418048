import { useStore } from 'vuex';
import ApiService from '@services/api.service';
import AuthService from "../../services/auth.service";
import { Notify } from 'quasar';

export const antennediplome = {
  state: {
    diplomes: [],
    diplome: null,
    selectedDiplome: null,
  },
  mutations: {
    setDiplomes(state, diplomes) {
      state.diplomes = diplomes
    },
    setDiplome(state, diplome) {
      state.diplome = diplome
    },
    setSelectedDiplome(state, diplome) {
      state.selectedDiplome = diplome;
    },
  },
  actions: {
    fetchDiplomes(context) {
      return ApiService.getRequest('/liste_diplomes').then(
        response => {
          context.commit('setDiplomes', response.data['hydra:member'])
        }
      )
        .catch( error => { console.error(error) } )
    },
    async fetchDiplomeById(context, Diplome) {
      return new Promise((resolve, reject) => {
        ApiService.getRequest(`${Diplome}`)
          .then(response => {
            context.commit('setDiplome', response.data);
            resolve();
          })
          .catch(error => {
            console.error(error);
            Notify.create({
              message: "Une erreur s'est produite",
              color: "red"
            });
            reject(error);
          });
      });
    },
    async fetchSelectedDiplomeById(context, diplomeId) {
      try {
        const response = await ApiService.getRequest(`/antenne_diplomes/${diplomeId}`);
        console.log('response.data', response.data)
        context.commit('setSelectedDiplome', response.data);
        return response.data;
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        throw error;
      }
    },
  },
  getters: {
    getDiplomes(state) {
      const store = useStore();
      const cfaHasInternalTraining = store.state?.auth?.user?.antenne?.cfa.formation_interne;
      const cfaAcceptOnlyApprentices = store.state?.auth?.user?.antenne?.cfa.accept_apprentis_only;
      const userIsSupervisor = AuthService.checkRole('ROLE_SUPERVISOR');
      //  * CFA ou Antennes test traitées en amont
      if (userIsSupervisor && !cfaHasInternalTraining) {
        // return state.diplomes; //pour le moment le cfa ne voit que ses diplomes
        return store.state?.auth?.user?.antenne.diplomes;
      } else if (userIsSupervisor && cfaHasInternalTraining && cfaAcceptOnlyApprentices) {
        return store.state?.auth?.user?.antenne?.cfa.diplomes;
      } else if (!userIsSupervisor && cfaHasInternalTraining && cfaAcceptOnlyApprentices) {
        return store.state?.auth?.user?.antenne.diplomes;
      } else {
        return store.state?.auth?.user?.antenne.diplomes ? store.state?.auth?.user?.antenne.diplomes : state.diplomes.filter(
          diplome => {
            return diplome.antennes.includes(store.state?.auth?.user?.antenne['@id']);
          });
      }
    },
    getDiplome(state) {
      return state.diplome;
    },
    getSelectedDiplome(state) {
      return state.selectedDiplome;
    },
  }
}
