import ApiService from "../../services/api.service";
import { Notify } from 'quasar';



export const schedulerActivities = {
  // *** C'est l'endroit où vous stockez les données.
  // ** On accède à l'état en utilisant ``this.$store.state`` dans les composants Vue, ou via ``mapState`` dans l'option ``computed``.
  state: {
    schedulerActivities: []
  },
  // *** Fonctions synchrones qui modifient l'état du store. Elles sont le seul moyen de modifier l'état.
  // ** On invoque une mutation en appelant ``commit`` dans les composants Vue.
  mutations: {
    setSchedulerActivities(state, schedulerActivities) {
      state.schedulerActivities = schedulerActivities;
    },
    callbackAddSchedulerActivity(state, newActivity) {
      state.schedulerActivities.push(newActivity);

      // Met à jour le tableau des intervenants associé à cette activité
      // const speakerIds = newActivity.speakers.map(speaker => speaker.id);
      // context.commit('schedulerSpeakers/callbackAddSchedulerActivityToSpeakers', { activity: newActivity, speakers: speakerIds }, { root: true });
    },
    callbackUpdateSchedulerActivity(state, updatedActivity) {
      const index = state.schedulerActivities.findIndex(activity => activity.id === updatedActivity.id);
      if (index !== -1) {
        state.schedulerActivities.splice(index, 1, updatedActivity);
      }
    },
    callbackRemoveSchedulerActivity(state, activityId) {
      for (var activity in state.schedulerActivities) {
        if (state.schedulerActivities[activity].id == activityId) {
          state.schedulerActivities.splice(activity, 1)
        }
      }
    },
  },
  // *** Fonctions qui peuvent être asynchrones et effectuer des opérations asynchrones, telles que les appels API, puis invoquer des mutations pour modifier l'état en conséquence.
  // ** On invoque une action en appelant ``dispatch`` dans les composants Vue.
  actions: {
    // GET :: Récupère la listes des activités
    fetchAllSchedulerActivities(context) {
      return ApiService.getRequest('/scheduler_activities').then(
        response => {
        context.commit('setSchedulerActivities', response.data['hydra:member']);
      }).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
      });
    },
    // POST :: Ajoute une nouvelle activité
    async addSchedulerActivity(context, newActivity) {
      try {
        const response = await ApiService.postRequest('/scheduler_activities', newActivity);
        if (response.status === 201) {
          context.commit('callbackAddSchedulerActivity', response.data);

            // Met à jour le store schedulerSpeakers
            const speakerIds = response.data.speakers.map(speaker => speaker.id);
            context.dispatch('addSchedulerActivityToSpeakers', { activity: response.data, speakers: speakerIds });

            Notify.create({
            message: 'L\'activité a été ajoutée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // PUT :: Met à jour une activité
    async updateSchedulerActivity(context, updatedActivity) {
      try {
        const response = await ApiService.patchRequest(`/scheduler_activities/${updatedActivity.id}`, updatedActivity);
        if (response.status === 200) {
          context.commit('callbackUpdateSchedulerActivity', updatedActivity);

          // console.log(updatedActivity)
          // Met à jour le store schedulerSpeakers
          context.dispatch('updateSchedulerActivityInSpeakers', updatedActivity);

          Notify.create({
            message: 'L\'activité a été mise à jour avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    // DELETE :: Supprime une activité
    async removeSchedulerActivity(context, activityId) {
      try {
        const response = await ApiService.deleteRequest(`/scheduler_activities/${activityId}`);
        if (response.status === 204) {
          context.commit('callbackRemoveSchedulerActivity', activityId);
          Notify.create({
            message: 'L\'activité a été supprimée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getSchedulerActivities(state) {
      return state.schedulerActivities;
    },
  },
};
