export default {
  address: 'Adresse',
  birthdate: 'Date de naissance',
  city: 'Ville',
  civility: 'Civilité',
  company_hours: 'Heures en entreprise',
  firstname: 'Prénom',
  formation_hours: 'Heures en formation',
  has_car: 'Véhicule',
  lastname: 'Nom',
  mail: 'Email',
  nationality: 'Nationalité',
  phone_number: 'Téléphone',
  postal_code: 'Code postal',
  social_security_number: 'Numéro de sécurité sociale',
  status: 'Statut',
  use_name: 'Nom d\'usage',
  type_cursus: 'Type cursus',
  is_minor_under_guardianship: 'Mineur sous tutelle',
  phone_number_fix: 'Tél. fixe',
  situation_matrimoniale: 'Situation matrimoniale',
  has_licence_b: "Permis B",
  country_of_birth: "Pays de naissance",
  antenne: 'Antenne de déroulement',
  antenne_administrative: 'Antenne administrative'
}
