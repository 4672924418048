import store from '../store/index'
import ApiService from './api.service'

// const parseJwt = (token) => {
//   const base64Url = token.split('.')[1];
//   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
//     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//   }).join(''));

//   return JSON.parse(jsonPayload);
// };

class AuthService {
  login(user) {
    store().state.globalstore.loading = true
    return ApiService
      .postRequest('authentication_token', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        let token = response && response.data && response.data.token
        if(!token) {
          throw "Got wrong response from 'authentication_token' route"
        }

        document.cookie = "auth-token=" + token + "; HttpOnly; Secure; samesite=strict"

        localStorage.setItem('token', JSON.stringify(token));
        return this.refreshUserData()
      })
      .finally(() => store().state.globalstore.loading = false)
  }

  logout() {
    localStorage.clear();
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
  }

  checkRole(role){
    const roles =  JSON.parse(localStorage.getItem('roles'));
    return (roles.includes(role))
  }

  refreshUserData() {
    return ApiService.getRequest('/users/me').then( response => {
        if(!response || !response.data || typeof response.data !== 'object') {
          throw 'Could not correctly parse response from route /users/me'
        }

        let user = response.data
        localStorage.setItem('roles', JSON.stringify(user.roles));

        delete user.roles
        localStorage.setItem('user', JSON.stringify(user));
        return user
      })
  }

  resetPassword(user) {
    return ApiService.postRequest('forgot_password/', {
      email: user.email,
    }).then( response => {
      // console.log(response)
    })
  }
}

export default new AuthService();
