import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const entrepriseSuivis = {
  state: {
    entrepriseSuivis: []
  },
  mutations: {
    setEntrepriseSuivis(state, entrepriseSuivis) {
      state.entrepriseSuivis = entrepriseSuivis;
    },
    callbackAddEntrepriseSuivi(state, newEntrepriseSuivi) {
      state.entrepriseSuivis.push(newEntrepriseSuivi);
    },
    callbackUpdateEntrepriseSuivi(state, updatedEntrepriseSuivi) {
      const index = state.entrepriseSuivis.findIndex(entrepriseSuivi => entrepriseSuivi.id === updatedEntrepriseSuivi.id);
      if (index !== -1) {
        state.entrepriseSuivis.splice(index, 1, updatedEntrepriseSuivi);
      }
    },
    callbackRemoveEntrepriseSuivi(state, entrepriseSuiviId) {
      for (var entrepriseSuivi in state.entrepriseSuivis) {
        if (state.entrepriseSuivis[entrepriseSuivi].id == entrepriseSuiviId) {
          state.entrepriseSuivis.splice(entrepriseSuivi, 1)
        }
      }
    },
  },
  actions: {
    // GET :: Récupère la liste des suivis de l'entreprise
    fetchEntrepriseSuivis(context, id_entreprise) {
      return ApiService.getRequest(`/entreprises/${id_entreprise}/suivis`).then(
        response => {
          context.commit('setEntrepriseSuivis', response.data['hydra:member']);
        }
      )
        .catch( error => { console.error(error) } );
    },
    // POST :: Ajoute un nouveau suivi
    async addEntrepriseSuivi(context, newEntrepriseSuivi) {
      console.log(newEntrepriseSuivi)
      try {
        const response = await ApiService.postRequest('/entreprise_suivis', newEntrepriseSuivi);
        if (response.status === 201) {
          context.commit('callbackAddEntrepriseSuivi', response.data);
          Notify.create({
            message: 'Le suivi a été ajouté avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          });
          return Promise.reject();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject();
      }
    },
    // PATCH :: Met à jour un suivi
    async updateEntrepriseSuivi(context, updatedEntrepriseSuivi) {
      try {
        const response = await ApiService.patchRequest('/entreprise_suivis/' + updatedEntrepriseSuivi.id, updatedEntrepriseSuivi);
        if (response.status === 200) {
          context.commit('callbackUpdateEntrepriseSuivi', response.data);
          Notify.create({
            message: 'Le suivi a été modifié avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          });
          return Promise.reject();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject();
      }
    },
    async removeEntrepriseSuivi(context, entrepriseSuiviId) {
      try {
        const response = await ApiService.deleteRequest('/entreprise_suivis/' + entrepriseSuiviId);
        if (response.status === 204) {
          context.commit('callbackRemoveEntrepriseSuivi', entrepriseSuiviId
          );
          Notify.create({
            message: 'Le suivi a été supprimé avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        }
      }
      catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject();
      }
    }
  },
  getters: {
    getEntrepriseSuivis(state) {
      return state.entrepriseSuivis;
    }
  }
}

