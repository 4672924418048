import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const antenneAlertes = {
    state: {
        alertes: [],
        alerte: null,
        object: null
    },
    mutations: {
        setAlertes(state, alertes){
            state.alertes = alertes
        },
        setAlerte(state, alerte){
            state.alerte = alerte
        }
    },
    actions: {
        /* Alertes action GET */
        fetchAlertes(context, url ) {
          return ApiService.getRequest(url, {}, true).then(
              response => {
                  context.commit('setAlertes', response.data['hydra:member']);
              },
          )
          .catch(error =>{
              console.error(error);
              Notify.create({
                  message: "Une erreur s'est produite",
                  color: 'red'
              })
          })
        },
        /*Ajouts des alertes*/
        async addAlerte({state}, data){
            try {
                const response = await ApiService.postRequest('global_alertes', data);
                if(response.status === 201){
                    this.commit('callbackEditAlertes', response.data);
                    Notify.create({
                        message: "Une alerte à été ajoutée.",
                        color: 'green'
                    })
                    return Promise.resolve();
                }
            }
            catch (error){
              console.error(error);
                Notify.create({
                    message: "Une erreur est survenue lors de la création de l'alerte",
                    color: 'red'
                })
                return Promise.reject(error)
            }
        },
    },
    getters: {
        getAlertes(state){
            return state.alertes;
        },
        getAlerte(state) {
            return state.alerte
        }
    }
}
