import ApiService from "@services/api.service";
import { Notify } from 'quasar';

export const antenneActions = {
  state: {
    actions: [],
    action: null,
  },
  mutations: {
    setActions(state, actions)
    {
      state.actions = actions;
    },
    setAction(state, action)
    {
      state.action = action
    }
  },
  actions: {
    fetchActionsFormation(context)
    {
      return ApiService.getRequest('antenne_actions').then(
        response => {
          context.commit('setActions', response.data['hydra:member']);
        }
      )
      .catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite lors de la récuperation des données",
          color: 'red'
        })
      })
    },
    fetchActionsFormationById(context, actionId)
    {
      console.log('coucou');
      return ApiService.getRequest(`${actionId}`).then(
        response => {
          console.log(response.data)
          context.commit('setAction', response.data);
        }
      )
        .catch(error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est prouite lors de la récupération des données",
            color: 'red'
          })
        })
    },
    addAction(context, formData)
    {
      try{
        const response = ApiService.postRequest('antenne_actions', formData)
        context.commit('setAction', response.data);
        Notify.create({
          messsage: "L'action a été ajoutée avec succès",
          color: 'green'
        })
      }catch(error){
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite a l'ajout de l'action de formation",
          color: 'red'
        })
      }
    }
  },
  getters: {
    getActions(state)
    {
      return state.actions;
    },
    getAction(state)
    {
      return state.action;
    }
  }
}
