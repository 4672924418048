import ApiService from "../../services/api.service";
import { Notify } from 'quasar'; 

export const schedulerOptions = {
  state: {
    schedulerOptions: [],
    schedulerOptionUser: {}
  },
  mutations: {
    setSchedulerOptions(state, schedulerOptions) {
      state.schedulerOptions = schedulerOptions;
    },
    setSchedulerOptionUser(state, schedulerOptionUser) {
      state.schedulerOptionUser = schedulerOptionUser;
    },
    callbackAddSchedulerOption(state, newOption) {
      state.schedulerOptions.push(newOption);
    },
    callbackUpdateSchedulerOption(state, updatedOption) {
      const index = state.schedulerOptions.findIndex(option => option.id === updatedOption.id);
      if (index !== -1) {
        state.schedulerOptions.splice(index, 1, updatedOption);
      }
    },
    callbackRemoveSchedulerOption(state, optionId) {
      for (var option in state.schedulerOptions) {
        if (state.schedulerOptions[option].id == optionId) {
          state.schedulerOptions.splice(option, 1)
        }
      }
    },
  },
  actions: {
    // GET :: Récupère les options du calendrier de l'utilisateur connecté
    fetchSchedulerOptionUser(context) {
      return ApiService.getRequest(`/scheduler_options/${store.state.auth.user.id}` ).then(
        response => {
        context.commit('setSchedulerOptionUser', response.data['hydra:member']);
      }).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
      });
    },
    // POST :: Ajoute un nouvel evenement
    async addSchedulerOptions(context, newOption) {
      try {
        const response = await ApiService.postRequest('/scheduler_Options', newOption);
        if (response.status === 201) {
            context.commit('callbackAddSchedulerOption', response.data);
            Notify.create({
            message: 'L\'évènement a été ajoutée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // PUT :: Met à jour un evenement
    // TODO: Quand je fais l'update par le store j'ai l'erreur des 30secondes
    async updateSchedulerOption(context, updatedOption) {
      try {
        const response = await ApiService.patchRequest(`/scheduler_Options/${updatedOption.id}`, updatedOption);
        if (response.status === 200) {
          context.commit('callbackUpdateSchedulerOption', updatedOption);
          Notify.create({
            message: 'L\'évènement a été mise à jour avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    // DELETE :: Supprime un evenement
    async removeSchedulerOption(context, OptionId) {
      try {
        const response = await ApiService.deleteRequest(`/scheduler_Options/${OptionId}`);
        if (response.status === 204) {
          context.commit('callbackRemoveSchedulerOption', OptionId);
          Notify.create({
            message: 'L\'évènement a été supprimée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getSchedulerOptions(state) {
      return state.schedulerOptions;
    },
    getSchedulerOptionUser(state) {
      return state.schedulerOptionUser;
    },
  }
};