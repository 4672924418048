import ApiService from '@services/api.service';
import { Notify } from 'quasar';

export const entreprises = {
  state: {
    entreprises: [],
    entreprise: null,
    entreprises_comptables: [],
  },
  mutations: {
    setEntreprises(state, entreprises) {
      state.entreprises = entreprises;
    },
    setEntreprise(state, entreprise) {
      state.entreprise = entreprise;
    },
    callbackAddDocumentEntreprise(state, payload) {
      state.objet = payload.objet;
    },
    setEntreprisesComptables(state, entreprises) {
      state.entreprises_comptables = entreprises;
    }
  },
  actions: {
    // GET :: Récupère la liste des entreprises pour prestataires
    fetchLightListeEntreprises(context){
      return ApiService.getRequest('/entreprises_light_list_presta').then(
        response => {
          context.commit('setEntreprises', response.data['hydra:member']);
        }
      )
        .catch( error => { console.error(error) } );
    },
    // GET :: Récupère la liste des entreprises comptables
    fetchEntreprisesComptables(context){
      return ApiService.getRequest('/entreprises_comptables?is_comptable=1').then(
        response => {
          context.commit('setEntreprisesComptables', response.data['hydra:member']);
        }
      )
        .catch( error => { console.error(error) } );
    },
    // POST :: Ajoute une nouvelle entreprise
    async addEntreprise({ state }, data) {
      try {
        const response = await ApiService.postRequest('entreprises', data);
        this.commit('setEntreprise', response.data);
        Notify.create({
          message: 'L\'entreprise a été créée avec succès.',
          color: 'green'
        });
        return response.data;

      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    },
    // PATCH :: Modifie une entreprise
    async editEntreprise({ state }, data) {
      try {
        const response = await ApiService.patchRequest(`/entreprises/${data.id_entreprise}`, data.data_form);
        if (response.status === 200) {
          this.commit('setEntreprise', response.data);
          Notify.create({
            message: 'L\'entreprise a été modifiée avec succès.',
            color: 'green'
          });
          return response.data;
        }

      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async addDocumentEntreprise({ state }, data) {
      try {
        const response = await ApiService.postRequest('/entreprise_documents', data);
        if (response.status === 201) {
          this.commit('callbackAddDocumentEntreprise', response.data);
          Notify.create({
            message: 'Le document a été ajouté avec succès.',
            color: 'green'
          })

          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    }
  },
  getters: {
    getEntreprises(state) {
      return state.entreprises;
    },
    getEntreprise(state) {
      return state.entreprise;
    },
    getEntreprisesComptables(state) {
      return state.entreprises_comptables;
    }
  }
}
