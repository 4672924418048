import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const antenneDiplomeSessions = {
  state: {
    sessions: [],
    session: null,
    objet: null
  },
  mutations: {
    setAntenneDiplomeSessions(state, sessions) {
      state.sessions = sessions
    },
    setAntenneDiplomeSession(state, session) {
      state.session = session
    },
  },
  actions: {
    fetchAntenneDiplomeSessions(context) {
      //  * liste des référentiels filtrés en amont par doctrine => le cfa voit les sessions de ses antennes
      return ApiService.getRequest('diplome_sessions').then(
        response => {
          context.commit('setAntenneDiplomeSessions', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error)
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        })
    },
    fetchAntenneActiveDiplomeSessions(context) {
      //  * liste des référentiels filtrés en amont par doctrine => le cfa ne voit que ses sessions
      return ApiService.getRequest('antenne_diplomes_sessions?etat=0').then(
        response => {
          context.commit('setAntenneDiplomeSessions', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error)
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        })
    },
  },
  getters: {
    getAntenneDiplomeSessions(state) {
      return state.sessions;
    },
    getAntenneDiplomeSession(state) {
      return state.session;
    }
  }
}
