import {createMemoryHistory, createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import routes from './routes'


// Vue.use(VueRouter)
// Vue.component('VueJsonToCsv', VueJsonToCsv)

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

export default function (/* { store, ssrContext } */) {
  const createHistory =
    process.env.MODE === 'ssr'
      ? createMemoryHistory
      : process.env.VUE_ROUTER_MODE === 'history'
        ? createWebHistory
        : createWebHashHistory

  const Router = new createRouter({
    scrollBehavior: () => ({x: 0, y: 0}),
    hashbang: false,
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
    ),
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })

  Router.beforeEach((to, from, next) => {

    const loggedIn = localStorage.getItem('user');

    // Vérifie si la route est publique
    // il faut ajouter meta: {publicPages: true} dans route.js
    if (to.matched.some(record => record.meta.publicPages)) {
      next(); // Toujours authorisé puisque public
    } else {
      // Si la page n'est pas publique alors on vérifie que l'utilisateur est loggué
      if (loggedIn) {
        try {
          const user = JSON.parse(loggedIn);
          localStorage.setItem('toPath', to.path);
          next();
        } catch (error) {
          console.error('Error parsing loggedIn data:', error);
          next('/');
        }
      } else {
        // Redirige l'utilisateur vers la page de login
        next('/');
      }
    }

  });

  return Router
}
