import ApiService from "../../services/api.service";
 import { Notify } from 'quasar'; 

export const schedulerEquipments = {
  // *** C'est l'endroit où vous stockez les données.
  // ** On accède à l'état en utilisant ``this.$store.state`` dans les composants Vue, ou via ``mapState`` dans l'option ``computed``.
  state: {
    schedulerEquipments: []
  },
  // *** Fonctions synchrones qui modifient l'état du store. Elles sont le seul moyen de modifier l'état.
  // ** On invoque une mutation en appelant ``commit`` dans les composants Vue.
  mutations: {
    setSchedulerEquipments(state, schedulerEquipments) {
      state.schedulerEquipments = schedulerEquipments;
    },
    callbackAddSchedulerEquipment(state, newEquipment) {
      state.schedulerEquipments.push(newEquipment);
    },
    callbackUpdateSchedulerEquipment(state, updatedEquipment) {
      const index = state.schedulerEquipments.findIndex(Equipment => Equipment.id === updatedEquipment.id);
      if (index !== -1) {
        state.schedulerEquipments.splice(index, 1, updatedEquipment);
      }
    },
    callbackRemoveSchedulerEquipment(state, equipmentId) {
      for (var equipment in state.schedulerEquipments) {
        if (state.schedulerEquipments[equipment].id == equipmentId) {
          state.schedulerEquipments.splice(equipment, 1)
        }
      }
    },
  },
  // *** Fonctions qui peuvent être asynchrones et effectuer des opérations asynchrones, telles que les appels API, puis invoquer des mutations pour modifier l'état en conséquence.
  // ** On invoque une action en appelant ``dispatch`` dans les composants Vue.
  actions: {
    // GET :: Récupère la listes des classes
    fetchAllSchedulerEquipments(context) {
      return ApiService.getRequest('/scheduler_equipments').then(
        response => {
        context.commit('setSchedulerEquipments', response.data['hydra:member']);
      }).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
      });
    },
    // POST :: Ajoute une nouvel équipement
    async addSchedulerEquipment(context, newEquipment) {
      try {
        const response = await ApiService.postRequest('/scheduler_equipments', newEquipment);
        if (response.status === 201) {
           context.commit('callbackAddSchedulerEquipment', response.data);
           Notify.create({
            message: 'L\'équipement a été ajouté avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // PUT :: Met à jour un équipement
    async updateSchedulerEquipment(context, updatedEquipment) {
      try {
        const response = await ApiService.patchRequest(`/scheduler_equipments/${updatedEquipment.id}`, updatedEquipment);
        if (response.status === 200) {
          context.commit('callbackUpdateSchedulerEquipment', updatedEquipment);
          Notify.create({
            message: 'L\'équipement a été mise à jour avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    // DELETE :: Supprime un équipement
    async removeSchedulerEquipment(context, equipmentId) {
      try {
        const response = await ApiService.deleteRequest(`/scheduler_equipments/${equipmentId}`);
        if (response.status === 204) {
          context.commit('callbackRemoveSchedulerEquipment', equipmentId);
          Notify.create({
            message: 'L\'équipement a été supprimée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getSchedulerEquipments(state) {
      return state.schedulerEquipments;
    },
  },
};