import ApiService from '@services/api.service';
import { Notify } from 'quasar';

export const entrepriseDocuments = {
  state: {
    documents: [],
    document: null,
    objet: null
  },
  mutations: {
    callbackEditDocumentEntreprise(state, payload) {
      state.objet = payload.objet;
    }
  },
  actions: {
    async editDocumentEntreprise({ state }, formdata) {
      try {
        const response = await ApiService.postRequest(`${formdata.uri['@id']}`, formdata.dataToSubmit);
        if (response.status === 201) {
          this.commit('callbackEditDocumentEntreprise', response.data);
          Notify.create({
            message: 'Le document a été modifié avec succès.',
            color: 'green'
          })

          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    }
  },
  getters: {
    getDocumentEntreprise(state) {
      return state.document;
    }
  }
}
