import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const apiSirene = {
  state: {
    sireneDatas: [],
    sireneData : null,
    object: null
  },
  mutations :{
    setSireneDatas(state, sireneDatas){
      state.sireneDatas = sireneDatas
    },
    setSireneData(state, sireneData){
      state.sireneData = sireneData
    }
  },
  actions: {
    /* Sirene Action GET */
    fecthSireneDatas(context, url)
    {
      return ApiService.getRequest(url).then(
        response => {
          context.commit('setSireneDatas', response.data)
        },
      )
        .catch(error =>{
          console.error(error);
          if(error.status === 404){
            Notify.create({
              message: "L'entreprise n'a pas été trouvée.",
              color: 'red'
            })
          }
          if(error.status === 301)
          Notify.create({
            message: "Une erreur s'est produite lors de la connexion.",
            color: 'red'
          })
          if(error.status === 503)
            Notify.create({
              message: "Le service est indisponible.",
              color: 'red'
            })
          if(error.status === 500)
          {
            Notify.create({
              message: "Une erreur est survenue lors de la connexion à l'API.",
              color: 'red'
            })
          }
        })
    },
  },
  getters: {
    getSireneDatas(state) {
      return state.sireneDatas;
    },
    getSireneData(state){
      return state.sireneData;
    }
  }
}
