import ApiService from "../../services/api.service";
import { useStore } from 'vuex';
import { Notify } from 'quasar';

export const antennereferentiels = {
  state: {
    referentiels: [],
    referentiel: null,
    objet: null
  },
  mutations: {
    setReferentiels(state, referentiels) {
      state.referentiels = referentiels
    },
    setReferentiel(state, referentiel) {
      state.referentiel = referentiel
    },
    callbackAddReferentiel(state, payload) {
      state.objet = payload.objet
    },
    callbackEditReferentiel(state, payload) {
      state.objet = payload.objet
    },
    callbackAddItem(state, payload) {
      state.objet = payload.objet
    }
  },
  actions: {
    /* CRUD REPOSITORY/ACTION (POST/PATCH/DELETE) */
    //  Ajouter un référentiel vide
    async addAntenneReferentiel({ state }, formData) {
      try {
        const response = await ApiService.postRequest('/antenne_referentiels', formData);
        if (response.status === 201) {
          this.commit('callbackAddReferentiel', response.data);
          Notify.create({
            message: 'Le référentiel a été ajouté avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * modification du label du référentiel
    async editRepository(context, data) {
      try {
        const response = await ApiService.patchRequest(`/antenne_referentiels_edit_label`, data);
        if (response.status === 200) {
          this.commit('callbackEditReferentiel', response.data)
          Notify.create({
            message: data.message,
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  * modification de l'état ou editable : lock, unlock, archived, deleted
    async statusReferentiel(context, form) {
      try {
        const response = await ApiService.patchRequest(`/antenne_referentiels/${form.id}`, { editable: form.editable, etat: form.etat });
        if (response.status === 200) {
          this.commit('callbackEditReferentiel', response.data)
          Notify.create({
            message: form.message,
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async deleteRepository(context, data) {
      try {
        const response = await ApiService.deleteRequest(`/antenne_referentiels/${data.id}`)
        if (response.status === 204) {
          this.commit('callbackEditReferentiel', response.data)
          Notify.create({
            message: 'Référentiel supprimé avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    //  Ajouter une copie d'un référentiel
    async cloneAntenneReferentiel(context, formData) {
      try {
        let response;

        //  si le sous-module est référentiels
        if (formData.fromModule === 'referentiels') {
          response = await ApiService.postRequest('/antenne_referentiel_copy', formData);
        }

        //  si le sous-module est actions pédagogiques
        if (formData.fromModule === 'actions') {
          response = await ApiService.postRequest('/antenne_actions_copy', formData);
        }

        if (response.status === 200) {
          this.commit('callbackAddReferentiel', response.data)
          Notify.create({
            message: 'Le référentiel a été copié avec succès.',
            color: 'green'
          })
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    /* CRUD COLLECTIONS (POST/PATCH/DELETE) */
    //  ajouter une unité de compétence, une compétence ou un descripteur
    async addEntity({ state }, formData) {
      try {
        if (formData.type === 'UNIT') {
         return await ApiService.postRequest('/antenne_referentiel_unite_competences', {
            label: formData.label,
            antenne_referentiel: '/antenne_referentiels/' + formData.id_parent,
          });
        }

        //  si le sous-module est référentiels
        if (formData.type === 'SKILL' && formData.fromModule === 'referentiels') {
          return await ApiService.postRequest('/antenne_referentiel_competences', {
            label: formData.label,
            unite_competences: '/antenne_referentiel_unite_competences/' + formData.id_parent,
            first_node: formData.firstNode,
          });
        }

        //  si le sous-module est actions pédagogiques
        if (formData.type === 'SKILL' && formData.fromModule === 'actions') {
          return await ApiService.postRequest('/antenne_referentiel_competences', {
            label: formData.label,
            antenne_referentiel: '/antenne_referentiels/' + formData.id_parent,
            first_node: formData.firstNode,
          });
        }

        if (formData.type === 'DESCRIPTOR') {
          return await ApiService.postRequest('/antenne_referentiel_descripteurs', {
            label: formData.label,
            competences: '/antenne_referentiel_competences/' + formData.id_parent,
          });
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    //  modifier une unité de compétence, une compétence ou un descripteur
    async editEntity(context, formData) {
      let response;

      try {
        if (formData.type === 'UNIT') {
          response = await ApiService.patchRequest(`/antenne_referentiel_unite_competences/${formData.id}`, {
            label: formData.label
          });
        }

        if (formData.type === 'SKILL') {
          response = await ApiService.patchRequest(`/antenne_referentiel_competences/${formData.id}`, {
            label: formData.label
          });
        }

        if (formData.type === 'DESCRIPTOR') {
          response = await ApiService.patchRequest(`/antenne_referentiel_descripteurs/${formData.id}`, {
            label: formData.label
          });
        }

        if (response.status === 200) {
          this.commit('callbackEditReferentiel', response.data);
          Notify.create({
            message: formData.message,
            color: 'green'
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    async deleteEntity(context, formData) {
      let response;

      try {
        if (formData.type === 'UNIT') {
          response = await ApiService.deleteRequest(`/antenne_referentiel_unite_competences/${formData.id}`)
        }

        if (formData.type === 'SKILL') {
          response = await ApiService.deleteRequest(`/antenne_referentiel_competences/${formData.id}`)
        }

        if (formData.type === 'DESCRIPTOR') {
          response = await ApiService.deleteRequest(`/antenne_referentiel_descripteurs/${formData.id}`)
        }

        if (response.status === 204) {
          this.commit('callbackEditReferentiel', response.data)
          Notify.create({
            message: formData.message,
            color: 'green'
          })
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    /* RÉFÉRENTIEL / ACTIONS (GET) */
    fetchReferentiels(context) {
      //  * liste des référentiels filtrés en amont par doctrine
      return ApiService.getRequest('/antenne_referentiels_repositories?type=repository').then(
        response => {
          context.commit('setReferentiels', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        })
    },
    fetchReferentielByDiplome(context, idDiplome) {
      //  * liste des référentiels filtrés en amont par doctrine
      return ApiService.getRequest(`/antenne_referentiels_repositories?etat=0&diplome=${idDiplome}`).then(
        response => {
          context.commit('setReferentiel', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },
    fetchActions(context) {
      //  * liste des actions pédagogiques filtrées en amont par doctrine
      return ApiService.getRequest('/antenne_referentiels_repositories?type=action').then(
        response => {
          context.commit('setReferentiels', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.error(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },
    fetchAllRepositories(context) {
      //  * liste des actions pédagogiques filtrées en amont par doctrine
      return ApiService.getRequest('/antenne_referentiels_repositories?editable=false&etat=0').then(
        response => {
          context.commit('setReferentiels', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.err(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },
    fetchAllRepositoriesByAntenna(context) {
      const store = useStore();
      const user      = store.state.auth.user;
      const antenneId = user.antenne.id;
      //  * liste des actions pédagogiques filtrées en amont par doctrine
      return ApiService.getRequest(`/antenne_referentiels_all_repositories`).then(
        response => {
          context.commit('setReferentiels', response.data['hydra:member'])
        }
      )
        .catch( error => {
          console.err(error);
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          })
        });
    },
    async fetchReferentielById(context, idReferentiel) {
      return new Promise((resolve, reject) => {
        ApiService.getRequest(`/antenne_referentiel_detail/${idReferentiel}`)
          .then(response => {
            context.commit('setReferentiel', response.data);
            resolve();
          })
          .catch(error => {
            console.error(error);
            Notify.create({
              message: "Une erreur s'est produite",
              color: "red"
            });
            reject(error);
          });
      });
    },
  },
  getters: {
    getReferentiels(state) {
      return state.referentiels;
    },
    getReferentiel(state) {
      return state.referentiel;
    }
  }
}
