import ApiService from '@services/api.service';
import { Notify } from 'quasar';

export const schedulerEventEmargements = {
  state: {
    eventSignatures: [],
    eventSignature: null,
    objet: null
  },
  mutations: {
    setEventSignatures(state, eventSignatures) {
      state.eventSignatures = eventSignatures;
    },
    setEventSignature(state, eventSignature) {
      state.eventSignature = eventSignature;
    },
    callbackEditEventSignature(state, payload) {
      state.objet = payload.objet;
    }
  },
  actions: {
    fetchEventsSignatures(context) {
      return ApiService.getRequest('/scheduler_event_emargements').then(
        response => {
          context.commit('setEventSignatures', response.data['hydra:member']);
        }
      ).catch( error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
      });
    },
    fetchEventsSignaturesBySpeakers(context, idSpeaker) {
      return ApiService.getRequest(`/scheduler_event_emargements?speakers=${idSpeaker}`).then(
        response => {
          context.commit('setEventSignatures', response.data['hydra:member']);
        }
      ).catch( error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
      });
    },
    /* CRUD ÉMARGEMENTS (POST / PATCH) */
      //  ajout émargement en 4 temps :
            //  - émargement des informations générales
            //  - émargement des stagiaires présent et absents
            //  - ajout des absences
            //  - ajout des absences au pointage
    async addSpeakerEventSignature({ context, dispatch }, data) {
      try {
        const response = await ApiService.postRequest('/scheduler_event_emargements', data);
        this.commit('setEventSignature', response.data);
        if (data.emargement_etudiants && data.emargement_etudiants.length > 0) {
          data.emargement_etudiants.forEach(e => {
            e.emargement = `/scheduler_event_emargements/${response.data.id}`;

            if (data.status === 1) {
              e.sign_off_at = data.sign_off_at;
              e.sign_off_by = data.sign_off_by
            }
            dispatch('addStudentEventSignature', e);
          })
        }

        //ne pas oublier de faire un promesse => si promesse successful alors message de succès
        Notify.create({
          message: 'L\'émargement a été ajouté avec succès.',
          color: 'green'
        });
        return response.data;
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    },
    //  édition émargement en 4 temps :
    //  - édition des informations générales
    //  - édition émargement des stagiaires présent et absents
    //  - ajout/suppression des absences
    //  - ajout/suppression des absences au pointage
    async editSpeakerEventSignature({ context, dispatch }, data) {
      try {
        const response = await ApiService.patchRequest(`${data['@id']}`, data);
        this.commit('callbackEditEventSignature', response.data);
        if (data.emargement_etudiants && data.emargement_etudiants.length > 0) {
          data.emargement_etudiants.forEach(e => {

            if (data.status === 1) {
              e.sign_off_at = data.sign_off_at;
              e.sign_off_by = data.sign_off_by
            }

            dispatch('editStudentEventSignature', e);
          })
        }
        Notify.create({
          message: 'L\'émargement a été modifié avec succès.',
          color: 'green'
        });
        return response.data;
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        console.error(error);
        return Promise.reject(error);
      }
    }
  },
  getters: {
    getEventSignatures(state) {
      return state.eventSignatures;
    },
    getEventSignature(state) {
      return state.eventSignature;
    }
  }
}
