import ApiService from "../../services/api.service";
import { Notify } from 'quasar';

export const schedulerClassrooms = {
  // *** C'est l'endroit où vous stockez les données.
  // ** On accède à l'état en utilisant ``this.$store.state`` dans les composants Vue, ou via ``mapState`` dans l'option ``computed``.
  state: {
    schedulerClassrooms: []
  },
  // *** Fonctions synchrones qui modifient l'état du store. Elles sont le seul moyen de modifier l'état.
  // ** On invoque une mutation en appelant ``commit`` dans les composants Vue.
  mutations: {
    setSchedulerClassrooms(state, schedulerClassrooms) {
      state.schedulerClassrooms = schedulerClassrooms;
    },
    callbackAddSchedulerClassroom(state, newClassroom) {
      state.schedulerClassrooms.push(newClassroom);
    },
    callbackUpdateSchedulerClassroom(state, updatedClassroom) {
      const index = state.schedulerClassrooms.findIndex(classroom => classroom.id === updatedClassroom.id);
      if (index !== -1) {
        state.schedulerClassrooms.splice(index, 1, updatedClassroom);
      }
    },
    callbackRemoveSchedulerClassroom(state, classroomId) {
      for (var classroom in state.schedulerClassrooms) {
        if (state.schedulerClassrooms[classroom].id == classroomId) {
          state.schedulerClassrooms.splice(classroom, 1)
        }
      }
    },
  },
  // *** Fonctions qui peuvent être asynchrones et effectuer des opérations asynchrones, telles que les appels API, puis invoquer des mutations pour modifier l'état en conséquence.
  // ** On invoque une action en appelant ``dispatch`` dans les composants Vue.
  actions: {
    // GET :: Récupère la listes des classes
    fetchAllSchedulerClassrooms(context) {
      return ApiService.getRequest('/scheduler_classrooms').then(
        response => {
        context.commit('setSchedulerClassrooms', response.data['hydra:member']);
      }).catch(error => {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
      });
    },
    // POST :: Ajoute une nouvelle classe
    async addSchedulerClassroom(context, newClassroom) {
      try {
        const response = await ApiService.postRequest('/scheduler_classrooms', newClassroom);
        if (response.status === 201) {
           context.commit('callbackAddSchedulerClassroom', response.data);
           Notify.create({
            message: 'La classe a été ajoutée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    // PUT :: Met à jour une classe
    async updateSchedulerClassroom(context, updatedClassroom) {
      try {
        const response = await ApiService.patchRequest(`/scheduler_classrooms/${updatedClassroom.id}`, updatedClassroom);
        if (response.status === 200) {
          context.commit('callbackUpdateSchedulerClassroom', updatedClassroom);
          Notify.create({
            message: 'La classe a été mise à jour avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject(error);
      }
    },
    // DELETE :: Supprime une classe
    async removeSchedulerClassroom(context, classroomId) {
      try {
        const response = await ApiService.deleteRequest(`/scheduler_classrooms/${classroomId}`);
        if (response.status === 204) {
          context.commit('callbackRemoveSchedulerClassroom', classroomId);
          Notify.create({
            message: 'La classe a été supprimée avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getSchedulerClassrooms(state) {
      return state.schedulerClassrooms;
    },
  },
};
