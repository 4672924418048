import ApiService from "../../services/api.service";
import { Notify } from 'quasar';


// Fonction pour formater une date au format "d/m/Y"
function formaterDateISO8601(dateString) {
  // Convertir la chaîne de date en objet Date
  var dateObj = new Date(dateString);

  // Obtenir le jour, le mois et l'année
  var jour = dateObj.getUTCDate().toString().padStart(2, '0'); // Ajoute un zéro devant si le nombre est inférieur à 10
  var mois = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0'); // Mois commence à 0, donc ajoutez 1 et un zéro devant si nécessaire
  var annee = dateObj.getUTCFullYear();
  // Formater la date selon le format d/m/Y
  var dateFormatee = jour + "/" + mois + "/" + annee;

  return dateFormatee;
}

export const entrepriseContacts = {
  state: {
    entrepriseContacts: []
  },
  mutations: {
    setEntrepriseContacts(state, entrepriseContacts) {
      state.entrepriseContacts = entrepriseContacts;
      // console.log(state.entrepriseContacts)
    },
    callbackAddEntrepriseContact(state, newEntrepriseContact) {
      // Vérifiez si state.entrepriseContacts est défini, sinon initialisez-le comme un tableau vide
      if (!state.entrepriseContacts) {
        state.entrepriseContacts = [];
      }

      state.entrepriseContacts.push(newEntrepriseContact);
    },
    callbackUpdateEntrepriseContact(state, updatedEntrepriseContact) {
      const index = state.entrepriseContacts.findIndex(contact => contact.id === updatedEntrepriseContact.id);
      if (index !== -1) {
        state.entrepriseContacts.splice(index, 1, updatedEntrepriseContact);
      }
    },
    callbackRemoveEntrepriseContact(state, entrepriseContactId) {
      for (var entrepriseContact in state.entrepriseContacts) {
        if (state.entrepriseContacts[entrepriseContact].id == entrepriseContactId) {
          state.entrepriseContacts.splice(entrepriseContact, 1)
        }
      }
    },
  },
  actions: {
    // GET :: Récupère la liste des contacts de l'entreprise
    fetchEntrepriseContacts(context, id_entreprise) {
      return ApiService.getRequest(`/entreprises/${id_entreprise}/contacts`).then(
        response => {
          let resultat = response.data['hydra:member'] ? response.data['hydra:member'] : response.data;

          // Ajoute un attribut @id, @type et format la birthdate à chaque contact
          resultat.forEach(contact => {
            contact['@id'] = `entreprise_contacts/${contact.id}`;
            contact['@type'] = 'EntrepriseContact';

            if(contact.birthdate) {
              // Convertit la date de naissance en format d/M/Y
              contact.birthdate = formaterDateISO8601(contact.birthdate.date);
            }
            });

          // console.log(resultat)

          context.commit('setEntrepriseContacts', resultat);
        }
      )
        .catch( error => { console.error(error) } );
    },
    // POST :: Ajoute un nouveau contact
    async addEntrepriseContact(context, newEntrepriseContact) {
      console.log(newEntrepriseContact)
      try {
        const response = await ApiService.postRequest('/entreprise_contacts', newEntrepriseContact);
        if (response.status === 201) {
          context.commit('callbackAddEntrepriseContact', response.data);
          console.log('newEntrepriseContact', response.data)
          Notify.create({
            message: 'Le contact a été ajouté avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          });
          return Promise.reject();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject();
      }
    },
    // PUT :: Met à jour un contact
    async updateEntrepriseContact(context, updatedEntrepriseContact) {
      try {
        const response = await ApiService.patchRequest(`/entreprise_contacts/${updatedEntrepriseContact.id}`, updatedEntrepriseContact);
        if (response.status === 200) {
          context.commit('callbackUpdateEntrepriseContact', response.data);
          Notify.create({
            message: 'Le contact a été mis à jour avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          Notify.create({
            message: "Une erreur s'est produite",
            color: "red"
          });
          return Promise.reject();
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        return Promise.reject();
      }
    },
    // DELETE :: Supprime un contact
    async removeEntrepriseContact(context, entrepriseContactId) {
      try {
        const response = await ApiService.deleteRequest(`/entreprise_contacts/${entrepriseContactId}`);
        if (response.status === 204) {
          context.commit('callbackRemoveEntrepriseContact', entrepriseContactId);
          Notify.create({
            message: 'Le contact a été supprimé avec succès.',
            color: 'green',
          });
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('La requête a échoué.'));
        }
      } catch (error) {
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        });
        console.error(error);
        return Promise.reject(error);
      }
    }
  },
  getters: {
    getEntrepriseContacts(state) {
      return state.entrepriseContacts;
    }
  }
}
