import ApiService from '@services/api.service';
import { Notify } from 'quasar';

export const etudiantPointages = {
  state: {
    pointages: [],
    pointage: null
  },
  mutations: {
    setPointages(state, pointages) {
      state.pointages = pointages;
    },
    setPointage(state, pointage) {
      state.pointage = pointage;
    }
  },
  actions: {
    fetchPointagesByAbsenceId(context, uri_absence) {
      return ApiService.getRequest(`pointages_light?absence=${uri_absence}`).then(
        response => {
          this.commit('setPointages', response.data['hydra:member'])
          return response.data['hydra:member'];
        }
      ).catch(error => { console.error(error) });
    },
    //  ajout d'une absence à un pointage
    async addEtudiantPointageAbsence({ state, dispatch }, data) {
      try {
        const etudiant = await dispatch('fecthEtudiant', data.etudiant_id);
        if (
          (
            etudiant.date_sortie !== null &&
            etudiant.date_sortie !== undefined
          ) ||
          (
            etudiant.date_sortie_prevue !== null &&
            etudiant.date_sortie_prevue !== undefined
          )
        ) {

          const response = ApiService.postRequest('/etudiant_pointage_add_absence', data.form);
          if (response) {
            Notify.create({
              message: "L'absence a été créée avec succès.",
              color: "green"
            });
            return Promise.resolve();
          }
        }
      } catch (error) {
        console.error(error)
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    deleteEtudiantPointageAbsence({ state }, formData) {
      try {
        const response = ApiService.postRequest('/etudiant_pointage_delete_absence', formData);
        Notify.create({
          message: "La suppression du pointage a été effectuée avec succès.",
          color: "green"
        });
        return Promise.resolve();
      } catch (error) {
        console.error(error)
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    }
  },
  getters: {
    getPointages(state) {
      return state.pointages;
    },
    getPointage(state) {
      return state.pointage;
    }
  }
}
