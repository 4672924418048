import ApiService from '@services/api.service';
import { Notify } from 'quasar';

export const prestataireDocuments = {
  state: {
    documents: [],
    document: null,
    objet: null
  },
  mutations: {
    setDocuments(state, documents) {
      state.documents = documents;
    },
    setDocument(state, document) {
      state.document = document;
    },
    callbackAddDocumentPrestataire(state, payload) {
      state.objet = payload.objet;
    },
    callbackEditDocumentPrestataire(state, payload) {
      state.objet = payload.objet;
    }
  },
  actions: {
    /* CRUD DOCUMENTS PRESTATAIRES (POST / PATCH) */
    async addDocumentPrestataire({ state }, data ) {
      try {
        const response = await ApiService.postRequest('/prestataire_documents', data);
        if (response.status === 201) {
          this.commit('callbackAddDocumentPrestataire', response.data);
          Notify.create({
            message: 'Le document a été ajouté avec succès.',
            color: 'green'
          })

          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
    async editDocumentPrestataire({ state }, formdata) {
      try {
        const response = await ApiService.postRequest(`${formdata.uri['@id']}`, formdata.dataToSubmit)
        if (response.status === 201) {
          this.commit('callbackEditDocumentPrestataire', response.data);
          Notify.create({
            message: 'Le document a été modifié avec succès.',
            color: 'green'
          })

          return response.data;
        }
      } catch (error) {
        console.error(error);
        Notify.create({
          message: "Une erreur s'est produite",
          color: "red"
        })
        return Promise.reject(error);
      }
    },
  },
  getters: {
    getDocumentsPrestataire(state) {
      return state.documents;
    },
    getDocumentPrestataire(state) {
      return state.document;
    }
  }
}
