import { boot } from 'quasar/wrappers'
import axios from 'axios'
import store from '@store' // your vuex store

// Créez une fonction pour gérer les erreurs 401
function handleUnauthorizedError(error) {
  console.log("error.response.status", error.response.status );
  if (error.response.status === 401) {
    console.log("Handling 401 error");
    localStorage.clear();
    window.location = '/';
  }
  throw error;
}

// Configurez Axios avec l'intercepteur d'erreur 401
function configureAxiosInstance(instance) {
  // console.log("instance", instance);
  instance.interceptors.response.use(response => response, handleUnauthorizedError);
}

// Configurez l'instance Axios par défaut
configureAxiosInstance(axios);

// Surchargez la méthode axios.create pour configurer également les intercepteurs
axios.create = ((originalCreate) => {
  return function () {
    const instance = originalCreate.apply(this, arguments);
    configureAxiosInstance(instance);
    return instance;
  };
})(axios.create);


export default boot(({ app }) => {
  // Pour une utilisation à l'intérieur des fichiers Vue (Options API) via this.$axios et this.$api
  app.config.globalProperties.$axios = axios;
});

export { axios };
